import { Link } from "gatsby"
import React, { useEffect } from "react"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import user from "../../../../assets/images/user.png"
import LeftCardAdvocateProfile from "../LeftCardAdvocateProfile"
import * as styles from "./styles.module.scss"
import axios from "axios"
import parse from 'html-react-parser';
import SpinLoad from "../../../shared/Spinner"


const AdvocateDetail = () => {

	const [user, setUser] = React.useState([])
	const [html, setHtml] = React.useState("")
	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		setLoading(true)
		const currentURL = window.location.href
		const id = currentURL.split("?id=")[1]

		axios.get(`https://api.galeahealth.com/profile/advocate-search?id=${id}`, {
		}).then(res => {
			console.log(res.data.data[0].message_to_clients)
			setUser(res.data.data[0])
			if(!res.data.data[0].message_to_clients){
				setHtml("")
			}else {
				setHtml(res.data.data[0].message_to_clients)
			}
			
			setLoading(false)
			console.log('Get user profile ',res.data.data)
		}).catch(err => {
			console.log(err)
		})
	}, [])

	return (
		<Container>
			{loading ? (
				<div className="d-flex justify-content-center align-items-center" style={{width:'100%',height:'75%'}}>
					<SpinLoad />
				</div>
			) : (
			<Row>
				<Col md={4} className="mt-5">
					<LeftCardAdvocateProfile team={user.team} address={user.address} level={user.level ? user.level.title :null} sport={user.sport ? user.sport.title :null}  license={user.license} img={user.image_url} name={user.name} />
				</Col>
				<Col md={8} className="mt-5">
					<div className={styles.add}>
						<h3>Why I want to be an advocate</h3>
					</div>
					<Card className={styles.rightCardAdv}>
						<Card.Body>
							<div>
								{parse(html)}
							</div>
						</Card.Body>
					</Card>

					<Row className="mb-5">
						{user.user_images &&
							user.user_images.map((item, index) => (
						<Col md={4} className="mt-4">
							<Image src={item.url} fluid />
						</Col>
							))}
					</Row>
				</Col>
			</Row>
				)}
		</Container>
	)
}

export default AdvocateDetail
