import React from "react"
import { Image } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import user from "../../../../assets/images/user.png"
import Paragraph from "../../../../components/shared/Paragraph"
import locationIcon from "../../../../assets/images/location.png"

const LeftCardAdvocateProfile = (props) => {
	console.log(props)
	return (
		<div className={styles.leftCard}>
			<div className="pt-3" style={{ textAlign: "center" }}>
				<img src={props.img || user} className={styles.leftCardImg} />
				<Paragraph className="mt-3 fw-bold">{props.name}</Paragraph>
				<h5 className={styles.leftCardH5}>
					<Image src={locationIcon} className="me-1" /> {props.address}
				</h5>
				<div className="mt-3 pb-5">
					<h6 className={styles.leftCardH6}>{props.level}</h6>
					<h6 className={styles.leftCardH6}>{props.team}</h6>
					<h6 className={styles.leftCardH6}>{props.sport}</h6>
				</div>
			</div>
		</div>
	)
}

export default LeftCardAdvocateProfile
