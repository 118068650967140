import { Main } from "../components/layout"
import React from "react"
import AdvocateDetail from "../components/pages/advocate/AdvocateDetail"

const AdvocateDetailPage = () => {
	return (
		<Main>
			<AdvocateDetail />
		</Main>
	)
}

export default AdvocateDetailPage